define("pesquisa-talentrh/pods/survey/form/configuration/component", ["exports", "ember-concurrency", "pesquisa-talentrh/utils/quiz", "moment"], function (_exports, _emberConcurrency, _quiz, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    quizCopy: {},
    openModal: false,
    sendEmail: false,
    sendSMS: false,
    isSaving: false,
    minDate: (0, _moment.default)(),
    showAutoSendCheck: Ember.computed('companyConfiguration', function () {
      return this.companyConfiguration && this.companyConfiguration.communicationApi;
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.isSaving) {
        const quiz = Ember.get(this, 'quiz');
        const sendEmail = quiz.sendType && quiz.sendType.includes('email') ? true : false;
        const sendSMS = quiz.sendType && quiz.sendType.includes('sms') ? true : false;
        const sendType = quiz.sendType ? quiz.sendType : [];
        Ember.set(this, 'sendEmail', sendEmail);
        Ember.set(this, 'sendSMS', sendSMS);
        Ember.setProperties(this.quizCopy, {
          autoSend: quiz.autoSend,
          sendType: [...sendType],
          daysToAnswer: quiz.daysToAnswer,
          causes: quiz.causes
        });
        const configEdit = Ember.get(this, 'configEdit');
        if (this.openModal && configEdit) {
          this.getCauses.perform();
        }
      }
    },
    _arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    getCauses: (0, _emberConcurrency.task)(function* () {
      if (this.quiz.causes) {
        const ids = this.quiz.causes.map(c => c.id);
        const causes = yield this.store.query('cause', {
          id: ids
        });
        Ember.setProperties(this.quiz, {
          causes
        });
      }
    }),
    save: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'isSaving', true);
      let quiz = Ember.get(this, 'quiz');
      let quizCopy = Ember.get(this, 'quizCopy');
      Ember.set(this, 'openModal', false);
      try {
        const configEdit = Ember.get(this, 'configEdit');
        if ((0, _quiz.default)(quiz, configEdit)) {
          if (configEdit) {
            let msg = '';
            if (quiz.autoSend) {
              msg += 'Os novos envios serão feitos de maneira automática, somente para os ex-colaboradores com causa de demissão informada.<br><br>';
            } else {
              msg += 'Os novos envios passarão a serem feitos de maneira manual, através da seleção dos ex-colaboradores.<br><br>';
            }
            if (quiz.autoSend && !this._arraysEqual(quiz.sendType, quizCopy.sendType)) {
              msg += 'Os novos envios passarão a serem feitos de acordo com a sua escolha para o e-mail e/ou telefone (SMS) existente no cadastro dos ex-colaboradores.<br><br>';
            }
            if (quiz.daysToAnswer !== quizCopy.daysToAnswer) {
              msg += 'Os novos envios passarão a respeitar a nova quantidade de dias definida como prazo para responder esse questionário.<br><br>';
            }
            if (!this._arraysEqual(quiz.causes, quizCopy.causes)) {
              msg += 'Os novos envios passarão a respeitar somente as causas que foram informadas.<br>';
            }
            const {
              value
            } = yield this.swal.confirm('Confirma alteração das configurações ?  A nova configuração passará a ser utilizada nos novos envios', {
              html: msg
            });
            if (!value) {
              Ember.set(this, 'openModal', true);
              return;
            }
          }
          if (quiz.causes && quiz.causes.length) {
            quiz.causes = quiz.causes.map(c => {
              return {
                id: c.id,
                description: c.description
              };
            });
          }
          if (this.quizType === 'demissional') {
            quiz.public = true;
            quiz.anonymous = false;
          }
          // salva como rascunho
          if (!configEdit) {
            quiz.status = "R";
          }
          if (quiz.answerThroughLink) {
            quiz.status = "A";
          }
          yield quiz.save();
          Ember.set(this, 'isSaving', false);
          this.swal.success('Questionário salvo com sucesso!');
          if (quiz.quizType === 'demissional') {
            Ember.get(this, 'router').transitionTo('survey-demissional');
          } else {
            Ember.get(this, 'router').transitionTo('survey-search');
          }
        } else {
          Ember.set(this, 'openModal', true);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    _checkSendType(prop, type) {
      Ember.set(this, prop, !this[prop]);
      let {
        sendType
      } = Ember.get(this, 'quiz');
      if (this[prop]) {
        if (!sendType.includes(type)) {
          sendType.push(type);
        }
      } else {
        sendType = sendType.filter(item => item !== type);
      }
      Ember.setProperties(this.quiz, {
        sendType
      });
    },
    actions: {
      checkPublic() {
        Ember.setProperties(this.quiz, {
          public: true,
          anonymous: false
        });
      },
      checkAnonymous() {
        Ember.setProperties(this.quiz, {
          anonymous: true,
          public: false
        });
      },
      checkMultipleAnswers() {
        Ember.setProperties(this.quiz, {
          allowMultipleAnswers: !this.quiz.allowMultipleAnswers
        });
      },
      checkSurveyPeriodic() {
        Ember.setProperties(this.quiz, {
          surveyPeriodic: !this.quiz.surveyPeriodic
        });
        Ember.set(this, 'quiz.answerThroughLink', false);
      },
      checkAnswerThroughLink() {
        Ember.setProperties(this.quiz, {
          answerThroughLink: !this.quiz.answerThroughLink
        });
        Ember.set(this, 'quiz.surveyPeriodic', false);
      },
      checkSendTypeEmail() {
        this._checkSendType('sendEmail', 'email');
      },
      checkSendTypeSMS() {
        this._checkSendType('sendSMS', 'sms');
      },
      onCheckAutoSend() {
        Ember.setProperties(this.quiz, {
          autoSend: !this.quiz.autoSend
        });
        if (!this.quiz.autoSend) {
          Ember.set(this, 'sendEmail', false);
          Ember.set(this, 'sendSMS', false);
          Ember.setProperties(this.quiz, {
            sendType: []
          });
        }
      },
      onClose() {
        Ember.set(this, 'isSaving', false);
        Ember.setProperties(this.quiz, {
          autoSend: this.quizCopy.autoSend,
          sendType: this.quizCopy.sendType,
          daysToAnswer: this.quizCopy.daysToAnswer,
          causes: this.quizCopy.causes
        });
      }
    }
  });
  _exports.default = _default;
});