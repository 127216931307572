define("pesquisa-talentrh/services/user-cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    handled: false,
    setInitialSessionCount() {
      this.session.set('data.logged', 1);
      this.session.set('data.sessionCount', 0);
    },
    invalidateAuthCookie() {
      this.session.invalidate();
    },
    getAuthTokenFromCookie() {
      const emberSimpleAuthRegex = /ember_simple_auth-session=([^;]+)/;
      const cookie = document.cookie;
      const matches = cookie.match(emberSimpleAuthRegex);
      if (!matches) {
        return null;
      }
      const decoded = decodeURIComponent(matches[1]);
      const parsed = JSON.parse(decoded);
      return parsed.authenticated.token;
    },
    async deleteUserDbToken() {
      try {
        await this.store.query('user-token', {
          user: this.session.user.id
        }).then(records => {
          records.forEach(record => {
            record.destroyRecord();
          });
        }).catch(e => {
          throw new Error(e);
        });
      } catch (e) {
        throw 'Error on delete user db token', e;
      } finally {
        this.session.set('data.logged', false);
        this.handled = true;
      }
    },
    async handleDeleteSession() {
      await this.deleteUserDbToken();
      await this.swal.fire({
        title: 'Sua sessão anterior foi encerrada!',
        text: 'Informe suas credenciais de acesso novamente.'
      }).then(() => {
        this.invalidateAuthCookie();
      });
    },
    async handleSessionCount() {
      const sessionCount = this.session.data.sessionCount;
      const logged = this.session.data.logged;
      const isReload = window.performance.navigation.type === 1;
      if (!sessionCount && !logged && !isReload) {
        return this.handleDeleteSession();
      }
      this.session.set('data.sessionCount', sessionCount + 1);
    },
    handleBeforeUnload() {
      const that = this;
      window.addEventListener('beforeunload', function () {
        const sessionCount = that.session.data.sessionCount;
        if (sessionCount > 0) {
          that.session.set('data.sessionCount', sessionCount - 1);
          that.session.set('data.logged', false);
        }
      });
    },
    async handle() {
      if (this.handled) {
        return;
      }
      this.handleBeforeUnload();
      const isLogged = this.session.data.sessionCount || this.session.data.logged;
      const isReload = window.performance.navigation.type === 1;
      if (!isLogged && !isReload) {
        return this.handleDeleteSession();
      }
      try {
        const token = this.getAuthTokenFromCookie();
        const userToken = await this.store.queryRecord('user-token', {
          user: this.session.user.id,
          token
        });
        if (!userToken) {
          return this.handleDeleteSession();
        }
        this.handled = true;
        return this.handleSessionCount();
      } catch (e) {
        console.error(e);
      }
    }
  });
  _exports.default = _default;
});