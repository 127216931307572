define("pesquisa-talentrh/pods/survey/index/item/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    fetch: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    changeValidityModal: false,
    percentage: 0,
    saveModal: false,
    openSurvey: Ember.computed.equal('quiz.status', 'A'),
    quizType: Ember.computed('quiz.{anonymous,public}', function () {
      let type = 'Privado';
      if (this.quiz.public) {
        type = 'Pesquisa identificada';
      }
      if (this.quiz.anonymous) {
        type = 'Pesquisa anônima';
      }
      if (this.quiz.allowMultipleAnswers && !this.quiz.anonymous) {
        type = 'Múltiplas respostas';
      }
      return type;
    }),
    status: Ember.computed('quiz.status', function () {
      let status = Ember.get(this, 'quiz.status');
      if (status === 'A') {
        return 'Aberto';
      }
      if (status === 'F') {
        return 'Fechado';
      }
      if (status === 'R') {
        return 'Rascunho';
      }
      return '';
    }),
    statusClass: Ember.computed('quiz.status', function () {
      let status = Ember.get(this, 'quiz.status');
      if (status === 'A') {
        return 'bg-success';
      }
      if (status === 'F') {
        return 'bg-danger';
      }
      if (status === 'R') {
        return 'bg-warning';
      }
      return;
    }),
    didRender() {
      this._super(...arguments);
      this.setAnswerCount();
    },
    deleteSurvey: (0, _emberConcurrency.task)(function* () {
      let {
        value
      } = yield this.swal.confirm('Confirmar exclusão do questionário?', {
        text: this.quiz.title
      });
      if (!value) {
        return;
      }
      try {
        yield this.quiz.destroyRecord();
        Ember.set(this, 'filters', {
          quizType: this.filters.quizType
        });
        this.toast.success('Questionário removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    exportResults: (0, _emberConcurrency.task)(function* () {
      try {
        this.toast.loading();
        let file = yield this.fetch.pdf(`quizzes/${this.quiz.id}/exportResults`);
        if (!file) {
          return;
        }
        let element = document.createElement('a');
        element.download = `${this.quiz.title}.xlsx`;
        element.href = file;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.toast.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    exportResultsCSV: (0, _emberConcurrency.task)(function* () {
      try {
        const report = yield this.fetch.request(`quizzes/${this.quiz.id}/exportResultsCSV`, {
          data: {
            anonymous: this.quiz.anonymous,
            quizType: this.quiz.quizType
          }
        });
        const blob = new Blob([report.data], {
          type: 'text/csv;charset=utf-8;'
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        this.swal.catch(error);
      }
    }),
    finishSurvey: (0, _emberConcurrency.task)(function* () {
      let confirm = 'Abrir questionário?';
      let text = 'O questionário poderá receber respostas';
      let message = 'Questionário aberto';
      let today = (0, _moment.default)().endOf('day').toDate();
      if (this.quiz.status === 'A') {
        confirm = 'Encerrar questionário?';
        text = 'O questionário não poderá mais receber respostas';
        message = 'Questionário encerrado';
        Ember.set(this, 'quiz.status', 'F');
      } else if (this.quiz.surveyPeriodic) {
        Ember.setProperties(this.quiz, {
          validity: null,
          status: 'A'
        });
      } else if (this.quiz.validity < today) {
        Ember.setProperties(this.quiz, {
          validity: today,
          status: 'A'
        });
      } else {
        Ember.setProperties(this.quiz, {
          status: 'A'
        });
      }
      try {
        let {
          value
        } = yield this.swal.confirm(confirm, {
          text
        });
        if (!value) {
          return this.quiz.rollbackAttributes();
        }
        yield this.quiz.save();
        this.toast.success(message);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    printSurvey: (0, _emberConcurrency.task)(function* () {
      try {
        this.toast.loading();
        let file = yield this.fetch.pdf(`quizzes/${this.quiz.id}/printSurvey`);
        if (!file) {
          return;
        }
        let element = document.createElement('a');
        element.download = `${this.quiz.title}.pdf`;
        element.href = file;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.toast.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    setAnswerCount() {
      let {
        answers,
        sentQuiz
      } = this.quiz;
      let percentage = Math.round(answers / sentQuiz * 100) || 0;
      Ember.set(this, 'percentage', percentage);
      document.getElementById(`answerCount${this.quiz.id}`).style.width = `${percentage}%`;
    },
    actions: {
      onClickSend() {
        if (this.quiz.autoSend) {
          return this.swal.warning('Pesquisa configurada para envio automático, não é possível fazer a seleção de ex-colaboradores.');
        }
        Ember.get(this, 'routing').transitionTo('survey.index.send', [this.quiz.id]);
      }
    }
  });
  _exports.default = _default;
});