define("pesquisa-talentrh/pods/application/route", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    userCookie: Ember.inject.service(),
    model() {
      return this.permission.load.perform();
    },
    async afterModel() {
      let session = Ember.get(this, 'session');
      if (!Ember.get(session, 'isAuthenticated')) {
        return;
      }

      // Remove o backup da session (login do superUser) caso seja do mesmo usuário logado
      if (session.user && session.data && session.data.keybkp && session.user.id === session.data.keybkp.tokenData.user.id) {
        this.session.set('data.keybkp', undefined);
      }
      let accountIsActive = this.permission.companyConfig.get('accountIsActive');
      if (!accountIsActive) {
        this.router.transitionTo('errors.inactive-account');
      }
      let availableModules = this.permission.companyConfig.get('subscription.availableModules');
      let isModuleAllowed = availableModules.includes('PESQUISA') && (this.permission.accessProfile.allowAccessSearches || this.permission.accessProfile.admin);
      if (!isModuleAllowed) {
        return Ember.get(this, 'swal').fire({
          title: 'Ops!',
          text: 'Você não tem acesso a este módulo. Entre em contato com seu administrador do sistema para mais informações.',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false
        }).then(() => {
          // Redireciona para um módulo que tiver acesso.
          let href = '';
          if (availableModules.includes('PORTAL_COLAB') || availableModules.includes('NELA')) {
            href = _environment.default.appUrl.social;
          } else if (availableModules.includes('PORTAL_GESTOR')) {
            href = _environment.default.appUrl.conta;
          } else if (availableModules.includes('AVD')) {
            href = _environment.default.appUrl.avd;
          } else if (availableModules.includes('PROJECT-MANAGER')) {
            href = _environment.default.appUrl.projetos;
          } else if (availableModules.includes('TED')) {
            href = _environment.default.appUrl.ted;
          } else {
            href = _environment.default.appUrl.agenda;
          }
          document.location.href = href;
        });
      }
      await this.userCookie.handle();
    },
    actions: {
      transitionRoute(route, params) {
        if (!route) {
          return;
        }
        if (params) {
          this.router.transitionTo(route, params);
        } else {
          this.router.transitionTo(route);
        }
      },
      loading(transition /*, originRoute*/) {
        let controller = this.controllerFor('application');
        Ember.set(controller, 'currentlyLoading', true);
        transition.promise.finally(() => {
          Ember.set(controller, 'currentlyLoading', false);
        });
      },
      error(adapterError) {
        if (!adapterError || !adapterError.errors || !adapterError.errors[0].status) {
          return;
        }
        switch (adapterError.errors[0].status) {
          case '400':
            if (adapterError.errors[0] && adapterError.errors[0].detail) {
              this.swal.fire('Ops!', adapterError.errors[0].detail, 'error');
            } else {
              this.swal.fire('Ops', 'Aconteceu ume erro inesperado por favor tente novamente mais tarde!', 'error');
            }
            break;
          case '403':
            this.swal.catch(adapterError);
            this.transitionTo('errors.forbidden');
            break;
          case '404':
            this.transitionTo('errors.not-found');
            break;
          case '500':
            if (adapterError.errors[0] && adapterError.errors[0].detail) {
              this.swal.fire('Erro 500', adapterError.errors[0].detail, 'error');
            } else {
              this.swal.fire('Ops', 'Aconteceu ume erro inesperado por favor tente novamente mais tarde! (500)', 'error');
            }
            break;
          case 0:
            this.transitionTo('errors.not-found');
            break;
        }
      },
      reloadRoute() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
  Ember.LinkComponent.reopen({
    attributeBindings: ['data-toggle', 'data-placement', 'title', 'data-target', 'data-toggle']
  });
  Ember.TextField.reopen({
    attributeBindings: ['data-toggle', 'data-placement', 'title', 'data-target', 'data-toggle']
  });
});